<template>
  <div>
    <PageTitlebar
      title="User Access"
      title-icon="sym_r_settings"
      sub-title="Manage access for all user accounts."
    >
      <template v-slot:actions>
        <q-btn
          v-if="$store.state.user.record.type === 'admin'"
          label="New User"
          :to="{ name: 'AdvertiserUserAccountCreator' }"
          color="primary"
        />
      </template>
    </PageTitlebar>

    <div
      v-if="$store.state.user.record.type !== 'admin'"
      class="column justify-center items-center q-mt-xl"
    >
      <h5 class="q-mb-sm">
        Insufficient Privileges.
      </h5>
      <p class="text-center text-font-secondary">
        You must be an admin user to access this page.
      </p>
    </div>

    <DataTable
      v-else
      table-key="advertiserAdminUserAccessOverview"
      row-key="id"
      title=""
      :columns="columns"
      :rows="users"
      :pagination="{
        sortBy: 'name',
        descending: false,
        page: 1,
        rowsPerPage: 10,
      }"
      :loading="loading"
      :downloadable="false"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:name="row">
        <div class="flex items-center">
          <q-icon
            v-if="row.pending"
            size="1.5em"
            name="sym_r_pending"
            color="subtle"
            class="q-mr-sm"
          >
            <q-tooltip anchor="center right" self="center left">
              Pending Verification
            </q-tooltip>
          </q-icon>
          <p>{{ row.first_name + " " + row.last_name }}</p>
        </div>
      </template>

      <template v-slot:type="row">
        <div class="flex items-center">
          <q-icon
            v-if="row.type !== 'regular'"
            name="sym_r_shield_person"
            color="gray-medium-dark"
            size="18px"
            class="q-mr-xs"
          />
          <p>{{ capitalizeText(row.type) }}</p>
          <q-badge
            v-if="row.type === 'regular'"
            color="gray-light"
            text-color="gray-medium-dark"
            :label="
              row.granted_access_advertiser_accounts.length == 1
                ? '1 Account'
                : row.granted_access_advertiser_accounts.length + ' Accounts'
            "
            style="font-size: 11px"
            class="q-ml-sm"
          />
        </div>
      </template>

      <template v-slot:actions="row">
        <ActionsDropdownButton :actions="getUserActionsList(row)" />
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import DataTable from "@/components/UI/DataTable.vue";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton.vue";
import { Dialog } from "quasar";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete.vue";

export default {
  name: "AdvertiserUserAccessOverview",
  components: {
    ActionsDropdownButton,
    DataTable,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      submitting: false,
      users: [],
      columns: [
        {
          name: "name",
          label: "Name",
          field: row => row.first_name + " " + row.last_name,
          hideField: true,
          type: "dimension",
          filterable: false
        },
        {
          name: "email",
          label: "Email Address",
          field: "email",
          type: "dimension",
          filterable: false
        },
        {
          name: "type",
          label: "Access Type",
          field: row => this.capitalizeText(row.type),
          type: "dimension",
          hideField: true
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false,
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    this.populateUsers();
  },
  methods: {
    populateUsers() {
      this.$store.dispatch("users/GET_REQUEST", {}).then(data => {
        setTimeout(() => {
          this.users = data;
          this.loading = false;
        }, 100);
      });
    },
    getUserActionsList(user) {
      let actionsList = [
        {
          action: () =>
            this.$router.push({
              name: "AdvertiserUserAccountEditor",
              params: {
                userId: user.id
              }
            }),
          label: "Manage Access",
          icon: "sym_r_edit"
        },
        {
          action: () => this.promptDeleteUser(user),
          label: "Delete User",
          icon: "sym_r_delete"
        }
      ];

      if (user.pending) {
        actionsList.unshift({
          action: () => this.resendVerificationEmail(user),
          label: "Resend Verification Email",
          icon: "sym_r_mail"
        });
      }

      return actionsList;
    },
    promptDeleteUser(user) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor:
            user.first_name + " " + user.last_name + " (" + user.email + ")"
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("users/POST_REQUEST", {
            endpoint: "delete",
            params: { id: user.id }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
            this.populateUsers();
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    },
    resendVerificationEmail(user) {
      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "send-user-verification-email",
          params: {
            email: user.email
          }
        })
        .then(() => {
          this.triggerActionOutcomeAlert("send", true);
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("send", false);
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
